import DateHelper from "@helper/DateHelper";
import OfferModel from "@model/OfferModel";
import useWindowSize from "src/hooks/useWindowSize";
import { Image, Popover, Rate } from "antd";
import _ from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Routes } from "../../config/routes";
import { useCurrency } from "src/hooks/CurrencyContext";
import { PriceFormatter } from "@libs/helper/PriceFormatter";

const OfferItem = observer(
  ({ data = new OfferModel(), isOnSuggestedOffers, index }) => {
    const [showArrow, setShowArrow] = useState(false);

    const { currency } = useCurrency();
    const { t, i18n } = useTranslation("common");
    const { width } = useWindowSize();

    const isMobile = width < 950;
    const fallbackImg = "/static/images/placeholder/provider.jpg";
    const dataCurrency = data?.basic_room?.price_currency || currency || "EUR";
    const priceDisplay = (price) =>
      PriceFormatter(price, dataCurrency, i18n.language);

    const _countryShow = () => {
      const list = [];
      if (data?.destinations != undefined) {
        if (data?.destinations.length > 0) {
          data?.destinations.map((d) => {
            list.push(d.country);
          });
        }
      }

      return list.length > 0 ? list[0] : "";
    };

    const _renderDate = (date) => {
      const list = [];
      date.map((d, i) => {
        const dan = d.split(" ");
        if (i == 0) {
          return list.push(
            t("open_from") +
              DateHelper.dateFormat(dan[0]) +
              " " +
              t("until") +
              " " +
              DateHelper.dateFormat(dan[1])
          );
        } else {
          return list.push(
            t("open_from").toLowerCase() +
              DateHelper.dateFormat(dan[0]) +
              " " +
              t("until") +
              " " +
              DateHelper.dateFormat(dan[1])
          );
        }
      });

      if (list.length > 2) {
        return date.length + " " + t("package_date_text");
      }
      return (
        <p className={"text-sm"}>
          {list.join(", " + t("and") + " ")} {t("opened")}
        </p>
      );
    };

    return (
      <a href={Routes.offerView(data.url_name)} key={data?.title + index}>
        <div className="offer-box mx-2">
          <div className="bg-white shadow rounded-2xl my-2 !h-[500px] relative">
            <div
              className="flex"
              onMouseEnter={() => setShowArrow(true)}
              onMouseLeave={() => setShowArrow(false)}
            >
              {data?.image?.length > 0 ? (
                <Swiper
                  modules={[Navigation, Pagination]}
                  pagination={
                    isMobile || showArrow
                      ? {
                          dynamicBullets: true,
                        }
                      : false
                  }
                  navigation={showArrow || isMobile ? true : false}
                  className="h-[208px] offer-arrow offer-slider-dots !w-full"
                >
                  {data?.image?.map((imageItem, i) => (
                    <SwiperSlide
                      className="relative !w-full"
                      key={imageItem + i}
                    >
                      <Image
                        src={imageItem || fallbackImg}
                        alt={`${data?.title} - ${i + 1}/${data?.image?.length}`}
                        className="object-cover rounded-t-2xl z-0 !w-full !h-full"
                        width="100%"
                        height="100%"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = fallbackImg;
                        }}
                        preview={false}
                        fallback={fallbackImg}
                        loading="lazy"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <Image
                  src={fallbackImg}
                  alt="moverii offer"
                  className={"object-cover rounded-t-2xl z-0 !w-full !h-full"}
                  width="100%"
                  height="100%"
                  preview={false}
                  fallback={fallbackImg}
                  loading="lazy"
                />
              )}
            </div>

            <div className="py-3 px-4">
              <h6 className="font-bold text-base line-clamp-2 h-fit text-[16px]">
                {data?.title}
              </h6>
              <div className="mt-4 flex flex-col space-y-3 pb-4">
                <div className="d-flex flex-row items-center space-x-2">
                  <div>
                    <Image
                      src={"/static/icons/market.svg"}
                      alt="offer location"
                      preview={false}
                      fallback={fallbackImg}
                      loading="lazy"
                    />
                  </div>
                  <p className="!text-sm">
                    {data?.destination != null && data?.destination != "" && (
                      <span className={"pr-1"}>{data?.destination + ", "}</span>
                    )}
                    {_countryShow()}
                  </p>
                </div>

                <div className="d-flex flex-row items-center  space-x-2">
                  <div className="w-[16px]">
                    <Image
                      src={"/static/icons/calc.svg"}
                      alt="calender icon"
                      style={{ width: "15px", height: "15px" }}
                      preview={false}
                      fallback={fallbackImg}
                      loading="lazy"
                      width={15}
                      height={15}
                    />
                  </div>
                  {data?.date != undefined && (
                    <div className="w-10/12 !text-sm">
                      {data?.date_type == "year" ? (
                        <p className="!text-sm">{t("offer.text_year_date")}</p>
                      ) : (
                        _renderDate(data?.date)
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-2 pb-2 absolute bottom-2 left-4 right-4  border-t border-black/10">
                {data.rate?.num_reviews > 0 ? (
                  <div className="flex flex-col">
                    <div className="flex">
                      <div>
                        {data?.rate?.type == "tripAdviser" ? (
                          <Image
                            className="pt-1 -ml-[6px]"
                            src={data.rate?.rating_image_url}
                            alt="tripAdviser rating"
                            preview={false}
                            fallback={fallbackImg}
                            loading="lazy"
                          />
                        ) : (
                          <div className={"flex"}>
                            <Rate
                              value={data.rate?.rate}
                              disabled
                              allowHalf
                              style={{ fontSize: "16px" }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <p className="text-sm text-gray-50 break-words">
                      (
                      {isNaN(data.rate?.num_reviews)
                        ? 0
                        : data.rate?.num_reviews}
                      ){" "}
                      {data?.rate?.type === "moverii"
                        ? `${t("offer.moverii_traveler_ratings")}`
                        : `${t("offer.TripAdvisor_traveler_ratings")}`}
                    </p>
                  </div>
                ) : (
                  <div className="h-8" />
                )}

                <div className="flex flex-row text-xs mt-4 items-center !justify-between">
                  <p
                    className={`text-gray-50 ${
                      isOnSuggestedOffers && "text-sm"
                    }`}
                  >
                    {t("from")}{" "}
                    <span className="text-black">
                      {data?.basic_room != undefined ? (
                        <>
                          {data?.basic_room?.price != "" ? (
                            <span
                              className={`font-bold ${
                                data?.discounted_price > 0
                                  ? "line-through relative"
                                  : ""
                              }`}
                            >
                              {priceDisplay(data?.basic_room?.price)}

                              {data?.discounted_price > 0 && (
                                <span className="!absolute -top-[15px] left-0 right-0 text-[#E43A36] font-bold">
                                  {priceDisplay(
                                    data?.basic_room?.price -
                                      data?.discounted_price
                                  )}
                                </span>
                              )}
                            </span>
                          ) : (
                            "0"
                          )}
                        </>
                      ) : (
                        "0"
                      )}
                    </span>
                    <p className="text-gray-50">
                      {t("offer.for")}{" "}
                      <span className="font-bold text-black">
                        {data?.basic_room?.duration}
                      </span>{" "}
                      {t("dayss")}
                    </p>
                  </p>
                  <div className="text-xs text-gray-50 flex flex-row space-x-1 items-center">
                    <Popover
                      overlayClassName="popover-transparent arrow-greenblue bg-lightBlueGreen rounded-lg pt-2 pb-0"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      content={
                        <div className="flex flex-col bg-lightBlueGreen !p-4 pb-5 rounded-t-sm rounded-b-xl shadow-md w-90">
                          <p className="font-bold -mt-2 text-base">
                            {t("search.included_price_popover")}
                          </p>
                          <div className="flex flex-col space-y-2 mt-3">
                            {_.uniqBy(data?.offerOption, "title")?.map(
                              (item, i) => {
                                return (
                                  <div
                                    className="flex gap-2 items-center text-grayDark"
                                    key={i}
                                  >
                                    <img
                                      src="/static/icons/checko.svg"
                                      className="!w-4 !h-4"
                                      alt="check icon"
                                    />
                                    <p>{item?.title}</p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      }
                      trigger="hover"
                    >
                      <div
                        className={`flex gap-x-1 ${
                          isOnSuggestedOffers && "text-sm !text-right"
                        }`}
                      >
                        <p className="text-black">
                          {t("offer.Included_in_the_price")}
                        </p>
                        <Image
                          src="/static/icons/info.svg"
                          alt="info icon"
                          width={14}
                          height={14}
                          preview={false}
                          fallback={fallbackImg}
                          loading="lazy"
                        />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
);

export default OfferItem;
